@import '../node_modules/leaflet/dist/leaflet.css';

.hideScrollBar::-webkit-scrollbar {
  display: none;
}
.leaflet-top > div > a,
.leaflet-control-attribution {
  display: none;
}
.MuiDataGrid-columnHeaders {
  background-color: #f4f6f8;
  color: #637381;
}

.MuiBox-root > .MuiFormControl-root {
  width: 100%;
  padding: 0 1rem;
}
.CookieConsent {
  z-index: 2000 !important;
}
.MuiLoadingButton-loadingIndicator {
  color: #fff;
}
.leaflet-pm-toolbar .leaflet-pm-icon-polygon {
  background-image: linear-gradient(transparent, transparent),
    url(./layouts//AddRanch//mapComponent/draw.svg) !important;
}
.leaflet-pm-toolbar .leaflet-pm-icon-polyline {
  background-image: linear-gradient(transparent, transparent),
    url(./layouts//AddRanch//mapComponent/draw.svg) !important;
}
.leaflet-pm-toolbar .leaflet-pm-icon-delete {
  background-image: linear-gradient(transparent, transparent),
    url(./layouts/AddRanch/mapComponent/clear.svg) !important;
}
.leaflet-pm-toolbar {
  margin-bottom: 1.5rem !important;
}
.leaflet-left > div > a {
  display: none;
}
.leaflet-gac-wrapper input {
  padding: 6px;
  width: 100%;
}
.leaflet-top,
.leaflet-left {
  width: 40%;
}
.leaflet-top,
.leaflet-left > .leaflet-gac-container {
  width: 50%;
}
.leaflet-control-attribution {
  display: none !important;
}
.popupCustom .leaflet-popup-tip,
.popupCustom .leaflet-popup-content-wrapper {
  background: #e0e0e0;
  color: #234c5e;
}
.leaflet-draw-edit-edit {
  display: none !important;
}
.leaflet-buttons-control-button {
  width: 4rem !important;
  height: 2rem !important;
  padding: 0 !important;
}
.leaflet-pm-toolbar a.leaflet-buttons-control-button {
  background: rgb(255, 255, 255);
}
.leaflet-pm-actions-container .pos-right {
  margin-top: 1.9px;
}
.gantt-container {
  height: calc(100vh - 150px);
}
.uppy-Dashboard-poweredBy .uppy-c-btn {
  display: none !important;
}

.uppy-StatusBar-actionBtn--done {
  display: none !important;
}
