.confirmed {
  background: linear-gradient(to right, #99ffcc -56%, #99ff99 103%);
}
.unconfirmed {
  background: linear-gradient(to right, #ff3300 -56%, #ffffff 103%);
}

.rework {
  background: linear-gradient(to right, #ffea00 -56%, #ffffff 103%);
}

.resub {
  background: linear-gradient(to right, #b284be -56%, #ffffff 103%);
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}